const extractHostname = function extractHostname(url: string) {
  if (!url) {
    return "";
  }
  try {
    const urlWithProtocol =
      url.includes("http://") || url.includes("https://")
        ? url
        : `https://${url}`;
    const newUrl = new URL(urlWithProtocol);
    const hostname = newUrl.hostname;
    return hostname;
  } catch (e: any) {
    if (e?.message?.includes("is not a valid URL")) {
      return url;
    }

    // eslint-disable-next-line no-console
    console.error("Invalid URL: ", e.message);
    return "";
  }
};

export { extractHostname };
