import * as LaunchDarkly from "launchdarkly-js-client-sdk";
import { useRuntimeConfig } from "nuxt/app";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import { until } from "~/utils/Async";

// WARNING: Use this composable strictly in client-side code only.
export const useLaunchDarkly = async () => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();
  const orgStore = useOrgStore();
  let ldClient: null | LaunchDarkly.LDClient;
  const maxWaitTime: number = 500;
  let maxWaitReached: boolean = false;

  const _isValidContext = (): boolean => {
    // Don't initialize the client with bad or incomplete data
    return (
      userStore.getUser !== null &&
      !!userStore.getUser?.id &&
      orgStore.getOrg !== null &&
      !!orgStore.getOrg?.id
    );
  };

  setTimeout(() => {
    maxWaitReached = true;
  }, maxWaitTime);

  await until(() => _isValidContext() || maxWaitReached);

  const user = {
    kind: "user",
    key: userStore.getUser?.id,
    email: userStore.getUser?.email,
    user_name: userStore.getUser?.name,
    is_impersonated: userStore.getUser?.is_impersonated,
    org_id: orgStore.getOrg?.id,
    org_name: orgStore.getOrg?.attributes?.name,
    org_plan: orgStore.getOrg?.attributes?.plan_name,
    internal_account: orgStore.getOrg?.attributes?.internal_account,
    digital_licenses: orgStore.getOrg?.attributes?.digital_licenses,
    print_licenses: orgStore.getOrg?.attributes?.print_licenses,
    license_countries: orgStore.getOrg?.attributes?.license_countries,
    license_region: orgStore.getOrg?.attributes?.license_region,
  };

  if (_isValidContext()) {
    try {
      ldClient = LaunchDarkly.initialize(config.public.LD_KEY as string, user);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("LaunchDarkly initialization failed:", e);
      ldClient = null;
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("LaunchDarkly client not initialized due to invalid context");
    ldClient = null;
  }

  const isLDClient = (): ldClient is LaunchDarkly.LDClient => {
    return ldClient !== null;
  };

  const getFlagValue = async (flag: string) => {
    if (!isLDClient()) {
      return false;
    }
    const ldClientInstance = ldClient as LaunchDarkly.LDClient;
    await ldClientInstance.waitForInitialization();
    return ldClientInstance.variation(flag);
  };

  return {
    isLDClient,
    getFlagValue,
  };
};
