const XX = [
  { name: "locations.unknown", iso: "XXX", region: "XX", id: 0 },
  { name: "locations.nonexistent_country", iso: "NEC", region: "XX", id: 1 },
];

const NA = [
  { name: "locations.anguilla", iso: "AIA", region: "NA", id: 9 },
  { name: "locations.antigua_and_barbuda", iso: "ATG", region: "NA", id: 11 },
  { name: "locations.aruba", iso: "ABW", region: "NA", id: 14 },
  { name: "locations.bahamas", iso: "BHS", region: "NA", id: 18 },
  { name: "locations.barbados", iso: "BRB", region: "NA", id: 21 },
  { name: "locations.belize", iso: "BLZ", region: "NA", id: 24 },
  { name: "locations.bermuda", iso: "BMU", region: "NA", id: 26 },
  { name: "locations.canada", iso: "CAN", region: "NA", id: 41 },
  { name: "locations.cayman_islands", iso: "CYM", region: "NA", id: 43 },
  { name: "locations.costa_rica", iso: "CRI", region: "NA", id: 55 },
  { name: "locations.cuba", iso: "CUB", region: "NA", id: 58 },
  { name: "locations.dominica", iso: "DMA", region: "NA", id: 64 },
  { name: "locations.dominican_republic", iso: "DOM", region: "NA", id: 65 },
  { name: "locations.el_salvador", iso: "SLV", region: "NA", id: 68 },
  { name: "locations.greenland", iso: "GRL", region: "NA", id: 88 },
  { name: "locations.grenada", iso: "GRD", region: "NA", id: 89 },
  { name: "locations.guadeloupe", iso: "GLP", region: "NA", id: 90 },
  { name: "locations.guatemala", iso: "GTM", region: "NA", id: 92 },
  { name: "locations.haiti", iso: "HTI", region: "NA", id: 97 },
  { name: "locations.honduras", iso: "HND", region: "NA", id: 100 },
  { name: "locations.jamaica", iso: "JAM", region: "NA", id: 112 },
  { name: "locations.martinique", iso: "MTQ", region: "NA", id: 141 },
  { name: "locations.mexico", iso: "MEX", region: "NA", id: 145 },
  { name: "locations.montserrat", iso: "MSR", region: "NA", id: 151 },
  { name: "locations.nicaragua", iso: "NIC", region: "NA", id: 161 },
  { name: "locations.panama", iso: "PAN", region: "NA", id: 172 },
  { name: "locations.puerto_rico", iso: "PRI", region: "NA", id: 180 },
  { name: "locations.saint_barthlemy", iso: "BLM", region: "NA", id: 186 },
  {
    name: "locations.saint_kitts_and_nevis",
    iso: "KNA",
    region: "NA",
    id: 188,
  },
  { name: "locations.saint_lucia", iso: "LCA", region: "NA", id: 189 },
  {
    name: "locations.saint_martin_french_part",
    iso: "MAF",
    region: "NA",
    id: 190,
  },
  {
    name: "locations.saint_pierre_and_miquelon",
    iso: "SPM",
    region: "NA",
    id: 191,
  },
  {
    name: "locations.saint_vincent_and_the_grenadines",
    iso: "VCT",
    region: "NA",
    id: 192,
  },
  { name: "locations.trinidad_and_tobago", iso: "TTO", region: "NA", id: 227 },
  {
    name: "locations.turks_and_caicos_islands",
    iso: "TCA",
    region: "NA",
    id: 231,
  },
  { name: "locations.united_states", iso: "USA", region: "NA", id: 237 },
  {
    name: "locations.virgin_islands_british",
    iso: "VGB",
    region: "NA",
    id: 244,
  },
  { name: "locations.virgin_islands_us", iso: "VIR", region: "NA", id: 245 },
];

const EU = [
  { name: "locations.land_islands", iso: "ALA", region: "EU", id: 3 },
  { name: "locations.albania", iso: "ALB", region: "EU", id: 4 },
  { name: "locations.andorra", iso: "AND", region: "EU", id: 7 },
  { name: "locations.austria", iso: "AUT", region: "EU", id: 16 },
  { name: "locations.belarus", iso: "BLR", region: "EU", id: 22 },
  { name: "locations.belgium", iso: "BEL", region: "EU", id: 23 },
  {
    name: "locations.bonaire_sint_eustatius_and_saba",
    iso: "BES",
    region: "EU",
    id: 29,
  },
  {
    name: "locations.bosnia_and_herzegovina",
    iso: "BIH",
    region: "EU",
    id: 30,
  },
  { name: "locations.bulgaria", iso: "BGR", region: "EU", id: 36 },
  { name: "locations.croatia", iso: "HRV", region: "EU", id: 57 },
  { name: "locations.curaao", iso: "CUW", region: "EU", id: 59 },
  { name: "locations.czech_republic", iso: "CZE", region: "EU", id: 61 },
  { name: "locations.denmark", iso: "DNK", region: "EU", id: 62 },
  { name: "locations.estonia", iso: "EST", region: "EU", id: 71 },
  { name: "locations.faroe_islands", iso: "FRO", region: "EU", id: 74 },
  { name: "locations.finland", iso: "FIN", region: "EU", id: 76 },
  { name: "locations.france", iso: "FRA", region: "EU", id: 77 },
  { name: "locations.germany", iso: "DEU", region: "EU", id: 84 },
  { name: "locations.gibraltar", iso: "GIB", region: "EU", id: 86 },
  { name: "locations.greece", iso: "GRC", region: "EU", id: 87 },
  { name: "locations.guernsey", iso: "GGY", region: "EU", id: 93 },
  {
    name: "locations.holy_see_vatican_city_state",
    iso: "VAT",
    region: "EU",
    id: 99,
  },
  { name: "locations.hungary", iso: "HUN", region: "EU", id: 102 },
  { name: "locations.iceland", iso: "ISL", region: "EU", id: 103 },
  { name: "locations.ireland", iso: "IRL", region: "EU", id: 108 },
  { name: "locations.isle_of_man", iso: "IMN", region: "EU", id: 109 },
  { name: "locations.italy", iso: "ITA", region: "EU", id: 111 },
  { name: "locations.jersey", iso: "JEY", region: "EU", id: 114 },
  { name: "locations.latvia", iso: "LVA", region: "EU", id: 124 },
  { name: "locations.liechtenstein", iso: "LIE", region: "EU", id: 129 },
  { name: "locations.lithuania", iso: "LTU", region: "EU", id: 130 },
  { name: "locations.luxembourg", iso: "LUX", region: "EU", id: 131 },
  {
    name: "locations.macedonia_the_former_yugoslav_republic_of",
    iso: "MKD",
    region: "EU",
    id: 133,
  },
  { name: "locations.malta", iso: "MLT", region: "EU", id: 139 },
  { name: "locations.moldova_republic_of", iso: "MDA", region: "EU", id: 147 },
  { name: "locations.monaco", iso: "MCO", region: "EU", id: 148 },
  { name: "locations.montenegro", iso: "MNE", region: "EU", id: 150 },
  { name: "locations.netherlands", iso: "NLD", region: "EU", id: 158 },
  { name: "locations.norway", iso: "NOR", region: "EU", id: 167 },
  { name: "locations.poland", iso: "POL", region: "EU", id: 178 },
  { name: "locations.portugal", iso: "PRT", region: "EU", id: 179 },
  { name: "locations.romania", iso: "ROU", region: "EU", id: 183 },
  { name: "locations.russian_federation", iso: "RUS", region: "EU", id: 184 },
  { name: "locations.san_marino", iso: "SMR", region: "EU", id: 194 },
  { name: "locations.serbia", iso: "SRB", region: "EU", id: 198 },
  {
    name: "locations.sint_maarten_dutch_part",
    iso: "SXM",
    region: "EU",
    id: 202,
  },
  { name: "locations.slovakia", iso: "SVK", region: "EU", id: 203 },
  { name: "locations.slovenia", iso: "SVN", region: "EU", id: 204 },
  { name: "locations.spain", iso: "ESP", region: "EU", id: 210 },
  {
    name: "locations.svalbard_and_jan_mayen",
    iso: "SJM",
    region: "EU",
    id: 214,
  },
  { name: "locations.sweden", iso: "SWE", region: "EU", id: 216 },
  { name: "locations.switzerland", iso: "CHE", region: "EU", id: 217 },
  { name: "locations.turkey", iso: "TUR", region: "EU", id: 229 },
  { name: "locations.ukraine", iso: "UKR", region: "EU", id: 234 },
  { name: "locations.united_kingdom", iso: "GBR", region: "EU", id: 236 },
];

const OC = [
  { name: "locations.american_samoa", iso: "ASM", region: "OC", id: 6 },
  { name: "locations.australia", iso: "AUS", region: "OC", id: 15 },
  { name: "locations.cook_islands", iso: "COK", region: "OC", id: 54 },
  { name: "locations.fiji", iso: "FJI", region: "OC", id: 75 },
  { name: "locations.french_polynesia", iso: "PYF", region: "OC", id: 79 },
  { name: "locations.guam", iso: "GUM", region: "OC", id: 91 },
  { name: "locations.kiribati", iso: "KIR", region: "OC", id: 118 },
  { name: "locations.marshall_islands", iso: "MHL", region: "OC", id: 140 },
  {
    name: "locations.micronesia_federated_states_of",
    iso: "FSM",
    region: "OC",
    id: 146,
  },
  { name: "locations.nauru", iso: "NRU", region: "OC", id: 156 },
  { name: "locations.new_caledonia", iso: "NCL", region: "OC", id: 159 },
  { name: "locations.new_zealand", iso: "NZL", region: "OC", id: 160 },
  { name: "locations.niue", iso: "NIU", region: "OC", id: 164 },
  { name: "locations.norfolk_island", iso: "NFK", region: "OC", id: 165 },
  {
    name: "locations.northern_mariana_islands",
    iso: "MNP",
    region: "OC",
    id: 166,
  },
  { name: "locations.palau", iso: "PLW", region: "OC", id: 170 },
  { name: "locations.papua_new_guinea", iso: "PNG", region: "OC", id: 173 },
  { name: "locations.pitcairn", iso: "PCN", region: "OC", id: 177 },
  { name: "locations.samoa", iso: "WSM", region: "OC", id: 193 },
  { name: "locations.solomon_islands", iso: "SLB", region: "OC", id: 205 },
  { name: "locations.tokelau", iso: "TKL", region: "OC", id: 225 },
  { name: "locations.tonga", iso: "TON", region: "OC", id: 226 },
  { name: "locations.tuvalu", iso: "TUV", region: "OC", id: 232 },
  {
    name: "locations.united_states_minor_outlying_islands",
    iso: "UMI",
    region: "OC",
    id: 238,
  },
  { name: "locations.vanuatu", iso: "VUT", region: "OC", id: 241 },
  { name: "locations.wallis_and_futuna", iso: "WLF", region: "OC", id: 246 },
];

const AS = [
  { name: "locations.afghanistan", iso: "AFG", region: "AS", id: 2 },
  { name: "locations.armenia", iso: "ARM", region: "AS", id: 13 },
  { name: "locations.azerbaijan", iso: "AZE", region: "AS", id: 17 },
  { name: "locations.bahrain", iso: "BHR", region: "AS", id: 19 },
  { name: "locations.bangladesh", iso: "BGD", region: "AS", id: 20 },
  { name: "locations.bhutan", iso: "BTN", region: "AS", id: 27 },
  {
    name: "locations.british_indian_ocean_territory",
    iso: "IOT",
    region: "AS",
    id: 34,
  },
  { name: "locations.brunei_darussalam", iso: "BRN", region: "AS", id: 35 },
  { name: "locations.cambodia", iso: "KHM", region: "AS", id: 39 },
  { name: "locations.china", iso: "CHN", region: "AS", id: 47 },
  { name: "locations.christmas_island", iso: "CXR", region: "AS", id: 48 },
  { name: "locations.cocos_keeling_islands", iso: "CCK", region: "AS", id: 49 },
  { name: "locations.cyprus", iso: "CYP", region: "AS", id: 60 },
  { name: "locations.georgia", iso: "GEO", region: "AS", id: 83 },
  { name: "locations.hong_kong_china", iso: "HKG", region: "AS", id: 101 },
  { name: "locations.india", iso: "IND", region: "AS", id: 104 },
  { name: "locations.indonesia", iso: "IDN", region: "AS", id: 105 },
  {
    name: "locations.iran_islamic_republic_of",
    iso: "IRN",
    region: "AS",
    id: 106,
  },
  { name: "locations.iraq", iso: "IRQ", region: "AS", id: 107 },
  { name: "locations.israel", iso: "ISR", region: "AS", id: 110 },
  { name: "locations.japan", iso: "JPN", region: "AS", id: 113 },
  { name: "locations.jordan", iso: "JOR", region: "AS", id: 115 },
  { name: "locations.kazakhstan", iso: "KAZ", region: "AS", id: 116 },
  {
    name: "locations.korea_democratic_peoples_republic_of",
    iso: "PRK",
    region: "AS",
    id: 119,
  },
  { name: "locations.korea_republic_of", iso: "KOR", region: "AS", id: 120 },
  { name: "locations.kuwait", iso: "KWT", region: "AS", id: 121 },
  { name: "locations.kyrgyzstan", iso: "KGZ", region: "AS", id: 122 },
  {
    name: "locations.lao_peoples_democratic_republic",
    iso: "LAO",
    region: "AS",
    id: 123,
  },
  { name: "locations.lebanon", iso: "LBN", region: "AS", id: 125 },
  { name: "locations.macao_china", iso: "MAC", region: "AS", id: 132 },
  { name: "locations.malaysia", iso: "MYS", region: "AS", id: 136 },
  { name: "locations.maldives", iso: "MDV", region: "AS", id: 137 },
  { name: "locations.mongolia", iso: "MNG", region: "AS", id: 149 },
  { name: "locations.myanmar", iso: "MMR", region: "AS", id: 154 },
  { name: "locations.nepal", iso: "NPL", region: "AS", id: 157 },
  { name: "locations.oman", iso: "OMN", region: "AS", id: 168 },
  { name: "locations.pakistan", iso: "PAK", region: "AS", id: 169 },
  { name: "locations.palestine_state_of", iso: "PSE", region: "AS", id: 171 },
  { name: "locations.philippines", iso: "PHL", region: "AS", id: 176 },
  { name: "locations.qatar", iso: "QAT", region: "AS", id: 181 },
  { name: "locations.saudi_arabia", iso: "SAU", region: "AS", id: 196 },
  { name: "locations.singapore", iso: "SGP", region: "AS", id: 201 },
  { name: "locations.sri_lanka", iso: "LKA", region: "AS", id: 211 },
  { name: "locations.syrian_arab_republic", iso: "SYR", region: "AS", id: 218 },
  {
    name: "locations.taiwan_province_of_china",
    iso: "TWN",
    region: "AS",
    id: 219,
  },
  { name: "locations.tajikistan", iso: "TJK", region: "AS", id: 220 },
  { name: "locations.thailand", iso: "THA", region: "AS", id: 222 },
  { name: "locations.timorleste", iso: "TLS", region: "AS", id: 223 },
  { name: "locations.turkmenistan", iso: "TKM", region: "AS", id: 230 },
  { name: "locations.united_arab_emirates", iso: "ARE", region: "AS", id: 235 },
  { name: "locations.uzbekistan", iso: "UZB", region: "AS", id: 240 },
  { name: "locations.viet_nam", iso: "VNM", region: "AS", id: 243 },
  { name: "locations.yemen", iso: "YEM", region: "AS", id: 248 },
];

const SA = [
  { name: "locations.argentina", iso: "ARG", region: "SA", id: 12 },
  {
    name: "locations.bolivia_plurinational_state_of",
    iso: "BOL",
    region: "SA",
    id: 28,
  },
  { name: "locations.brazil", iso: "BRA", region: "SA", id: 33 },
  { name: "locations.chile", iso: "CHL", region: "SA", id: 46 },
  { name: "locations.colombia", iso: "COL", region: "SA", id: 50 },
  { name: "locations.ecuador", iso: "ECU", region: "SA", id: 66 },
  {
    name: "locations.falkland_islands_malvinas",
    iso: "FLK",
    region: "SA",
    id: 73,
  },
  { name: "locations.french_guiana", iso: "GUF", region: "SA", id: 78 },
  { name: "locations.guyana", iso: "GUY", region: "SA", id: 96 },
  { name: "locations.paraguay", iso: "PRY", region: "SA", id: 174 },
  { name: "locations.peru", iso: "PER", region: "SA", id: 175 },
  { name: "locations.suriname", iso: "SUR", region: "SA", id: 213 },
  { name: "locations.uruguay", iso: "URY", region: "SA", id: 239 },
  {
    name: "locations.venezuela_bolivarian_republic_of",
    iso: "VEN",
    region: "SA",
    id: 242,
  },
];

const AF = [
  { name: "locations.algeria", iso: "DZA", region: "AF", id: 5 },
  { name: "locations.angola", iso: "AGO", region: "AF", id: 8 },
  { name: "locations.benin", iso: "BEN", region: "AF", id: 25 },
  { name: "locations.botswana", iso: "BWA", region: "AF", id: 31 },
  { name: "locations.burkina_faso", iso: "BFA", region: "AF", id: 37 },
  { name: "locations.burundi", iso: "BDI", region: "AF", id: 38 },
  { name: "locations.cameroon", iso: "CMR", region: "AF", id: 40 },
  { name: "locations.cabo_verde", iso: "CPV", region: "AF", id: 42 },
  {
    name: "locations.central_african_republic",
    iso: "CAF",
    region: "AF",
    id: 44,
  },
  { name: "locations.chad", iso: "TCD", region: "AF", id: 45 },
  { name: "locations.comoros", iso: "COM", region: "AF", id: 51 },
  { name: "locations.congo", iso: "COG", region: "AF", id: 52 },
  {
    name: "locations.congo_the_democratic_republic_of_the",
    iso: "COD",
    region: "AF",
    id: 53,
  },
  { name: "locations.cte_divoire", iso: "CIV", region: "AF", id: 56 },
  { name: "locations.djibouti", iso: "DJI", region: "AF", id: 63 },
  { name: "locations.egypt", iso: "EGY", region: "AF", id: 67 },
  { name: "locations.equatorial_guinea", iso: "GNQ", region: "AF", id: 69 },
  { name: "locations.eritrea", iso: "ERI", region: "AF", id: 70 },
  { name: "locations.ethiopia", iso: "ETH", region: "AF", id: 72 },
  { name: "locations.gabon", iso: "GAB", region: "AF", id: 81 },
  { name: "locations.gambia", iso: "GMB", region: "AF", id: 82 },
  { name: "locations.ghana", iso: "GHA", region: "AF", id: 85 },
  { name: "locations.guinea", iso: "GIN", region: "AF", id: 94 },
  { name: "locations.guineabissau", iso: "GNB", region: "AF", id: 95 },
  { name: "locations.kenya", iso: "KEN", region: "AF", id: 117 },
  { name: "locations.lesotho", iso: "LSO", region: "AF", id: 126 },
  { name: "locations.liberia", iso: "LBR", region: "AF", id: 127 },
  { name: "locations.libya", iso: "LBY", region: "AF", id: 128 },
  { name: "locations.madagascar", iso: "MDG", region: "AF", id: 134 },
  { name: "locations.malawi", iso: "MWI", region: "AF", id: 135 },
  { name: "locations.mali", iso: "MLI", region: "AF", id: 138 },
  { name: "locations.mauritania", iso: "MRT", region: "AF", id: 142 },
  { name: "locations.mauritius", iso: "MUS", region: "AF", id: 143 },
  { name: "locations.mayotte", iso: "MYT", region: "AF", id: 144 },
  { name: "locations.morocco", iso: "MAR", region: "AF", id: 152 },
  { name: "locations.mozambique", iso: "MOZ", region: "AF", id: 153 },
  { name: "locations.namibia", iso: "NAM", region: "AF", id: 155 },
  { name: "locations.niger", iso: "NER", region: "AF", id: 162 },
  { name: "locations.nigeria", iso: "NGA", region: "AF", id: 163 },
  { name: "locations.runion", iso: "REU", region: "AF", id: 182 },
  { name: "locations.rwanda", iso: "RWA", region: "AF", id: 185 },
  {
    name: "locations.saint_helena_ascension_and_tristan_da_cunha",
    iso: "SHN",
    region: "AF",
    id: 187,
  },
  {
    name: "locations.sao_tome_and_principe",
    iso: "STP",
    region: "AF",
    id: 195,
  },
  { name: "locations.senegal", iso: "SEN", region: "AF", id: 197 },
  { name: "locations.seychelles", iso: "SYC", region: "AF", id: 199 },
  { name: "locations.sierra_leone", iso: "SLE", region: "AF", id: 200 },
  { name: "locations.somalia", iso: "SOM", region: "AF", id: 206 },
  { name: "locations.south_africa", iso: "ZAF", region: "AF", id: 207 },
  { name: "locations.south_sudan", iso: "SSD", region: "AF", id: 209 },
  { name: "locations.sudan", iso: "SDN", region: "AF", id: 212 },
  { name: "locations.swaziland", iso: "SWZ", region: "AF", id: 215 },
  {
    name: "locations.tanzania_united_republic_of",
    iso: "TZA",
    region: "AF",
    id: 221,
  },
  { name: "locations.togo", iso: "TGO", region: "AF", id: 224 },
  { name: "locations.tunisia", iso: "TUN", region: "AF", id: 228 },
  { name: "locations.uganda", iso: "UGA", region: "AF", id: 233 },
  { name: "locations.western_sahara", iso: "ESH", region: "AF", id: 247 },
  { name: "locations.zambia", iso: "ZMB", region: "AF", id: 249 },
  { name: "locations.zimbabwe", iso: "ZWE", region: "AF", id: 250 },
];

const AN = [
  { name: "locations.antarctica", iso: "ATA", region: "AN", id: 10 },
  { name: "locations.bouvet_island", iso: "BVT", region: "AN", id: 32 },
  {
    name: "locations.french_southern_territories",
    iso: "ATF",
    region: "AN",
    id: 80,
  },
  {
    name: "locations.heard_island_and_mcdonald_islands",
    iso: "HMD",
    region: "AN",
    id: 98,
  },
  {
    name: "locations.south_georgia_and_the_south_sandwich_islands",
    iso: "SGS",
    region: "AN",
    id: 208,
  },
];

const ALL = XX.concat(EU)
  .concat(NA)
  .concat(SA)
  .concat(AF)
  .concat(OC)
  .concat(AS)
  .concat(AN);

// function isoFromId(id = XX.id) {
//   return ALL.findBy("id", id).iso;
// }

// function idFromIso(iso = XX.iso) {
//   return ALL.findBy("iso", iso).id;
// }
// /**
//  * Function that returns an array of 'iso' values for provided array of location ids.
//  * @param {Array} ids
//  */
// function isoArrayFromIds(ids = []) {
//   const isoArray = [];
//   ids.forEach((id) => {
//     isoArray.pushObject(ALL.findBy("id", id).iso);
//   });
//   return isoArray;
// }

// function idArrayFromIso(isos = []) {
//   const idArray = [];
//   isos.forEach((iso) => {
//     idArray.pushObject(ALL.findBy("iso", iso).id);
//   });
//   return idArray;
// }

const locationData = [
  ["Unknown", "XXX", "XX"],
  ["NonExistentCountry", "NEC", "XX"],
  ["Afghanistan", "AFG", "AS"],
  ["Åland Islands", "ALA", "EU"],
  ["Albania", "ALB", "EU"],
  ["Algeria", "DZA", "AF"],
  ["American Samoa", "ASM", "OC"],
  ["Andorra", "AND", "EU"],
  ["Angola", "AGO", "AF"],
  ["Anguilla", "AIA", "NA"],
  ["Antarctica", "ATA", "AN"],
  ["Antigua and Barbuda", "ATG", "NA"],
  ["Argentina", "ARG", "SA"],
  ["Armenia", "ARM", "AS"],
  ["Aruba", "ABW", "NA"],
  ["Australia", "AUS", "OC"],
  ["Austria", "AUT", "EU"],
  ["Azerbaijan", "AZE", "AS"],
  ["Bahamas", "BHS", "NA"],
  ["Bahrain", "BHR", "AS"],
  ["Bangladesh", "BGD", "AS"],
  ["Barbados", "BRB", "NA"],
  ["Belarus", "BLR", "EU"],
  ["Belgium", "BEL", "EU"],
  ["Belize", "BLZ", "NA"],
  ["Benin", "BEN", "AF"],
  ["Bermuda", "BMU", "NA"],
  ["Bhutan", "BTN", "AS"],
  ["Bolivia, Plurinational State of", "BOL", "SA"],
  ["Bonaire, Sint Eustatius and Saba", "BES", "EU"],
  ["Bosnia and Herzegovina", "BIH", "EU"],
  ["Botswana", "BWA", "AF"],
  ["Bouvet Island", "BVT", "AN"],
  ["Brazil", "BRA", "SA"],
  ["British Indian Ocean Territory", "IOT", "AS"],
  ["Brunei Darussalam", "BRN", "AS"],
  ["Bulgaria", "BGR", "EU"],
  ["Burkina Faso", "BFA", "AF"],
  ["Burundi", "BDI", "AF"],
  ["Cambodia", "KHM", "AS"],
  ["Cameroon", "CMR", "AF"],
  ["Canada", "CAN", "NA"],
  ["Cabo Verde", "CPV", "AF"],
  ["Cayman Islands", "CYM", "NA"],
  ["Central African Republic", "CAF", "AF"],
  ["Chad", "TCD", "AF"],
  ["Chile", "CHL", "SA"],
  ["China", "CHN", "AS"],
  ["Christmas Island", "CXR", "AS"],
  ["Cocos (Keeling) Islands", "CCK", "AS"],
  ["Colombia", "COL", "SA"],
  ["Comoros", "COM", "AF"],
  ["Congo", "COG", "AF"],
  ["Congo, the Democratic Republic of the", "COD", "AF"],
  ["Cook Islands", "COK", "OC"],
  ["Costa Rica", "CRI", "NA"],
  ["Côte d'Ivoire", "CIV", "AF"],
  ["Croatia", "HRV", "EU"],
  ["Cuba", "CUB", "NA"],
  ["Curaçao", "CUW", "EU"],
  ["Cyprus", "CYP", "AS"],
  ["Czech Republic", "CZE", "EU"],
  ["Denmark", "DNK", "EU"],
  ["Djibouti", "DJI", "AF"],
  ["Dominica", "DMA", "NA"],
  ["Dominican Republic", "DOM", "NA"],
  ["Ecuador", "ECU", "SA"],
  ["Egypt", "EGY", "AF"],
  ["El Salvador", "SLV", "NA"],
  ["Equatorial Guinea", "GNQ", "AF"],
  ["Eritrea", "ERI", "AF"],
  ["Estonia", "EST", "EU"],
  ["Ethiopia", "ETH", "AF"],
  ["Falkland Islands (Malvinas)", "FLK", "SA"],
  ["Faroe Islands", "FRO", "EU"],
  ["Fiji", "FJI", "OC"],
  ["Finland", "FIN", "EU"],
  ["France", "FRA", "EU"],
  ["French Guiana", "GUF", "SA"],
  ["French Polynesia", "PYF", "OC"],
  ["French Southern Territories", "ATF", "AN"],
  ["Gabon", "GAB", "AF"],
  ["Gambia", "GMB", "AF"],
  ["Georgia", "GEO", "AS"],
  ["Germany", "DEU", "EU"],
  ["Ghana", "GHA", "AF"],
  ["Gibraltar", "GIB", "EU"],
  ["Greece", "GRC", "EU"],
  ["Greenland", "GRL", "NA"],
  ["Grenada", "GRD", "NA"],
  ["Guadeloupe", "GLP", "NA"],
  ["Guam", "GUM", "OC"],
  ["Guatemala", "GTM", "NA"],
  ["Guernsey", "GGY", "EU"],
  ["Guinea", "GIN", "AF"],
  ["Guinea-Bissau", "GNB", "AF"],
  ["Guyana", "GUY", "SA"],
  ["Haiti", "HTI", "NA"],
  ["Heard Island and McDonald Islands", "HMD", "AN"],
  ["Holy See (Vatican City State)", "VAT", "EU"],
  ["Honduras", "HND", "NA"],
  ["Hong Kong (China)", "HKG", "AS"],
  ["Hungary", "HUN", "EU"],
  ["Iceland", "ISL", "EU"],
  ["India", "IND", "AS"],
  ["Indonesia", "IDN", "AS"],
  ["Iran, Islamic Republic of", "IRN", "AS"],
  ["Iraq", "IRQ", "AS"],
  ["Ireland", "IRL", "EU"],
  ["Isle of Man", "IMN", "EU"],
  ["Israel", "ISR", "AS"],
  ["Italy", "ITA", "EU"],
  ["Jamaica", "JAM", "NA"],
  ["Japan", "JPN", "AS"],
  ["Jersey", "JEY", "EU"],
  ["Jordan", "JOR", "AS"],
  ["Kazakhstan", "KAZ", "AS"],
  ["Kenya", "KEN", "AF"],
  ["Kiribati", "KIR", "OC"],
  ["Korea, Democratic People's Republic of", "PRK", "AS"],
  ["Korea, Republic of", "KOR", "AS"],
  ["Kuwait", "KWT", "AS"],
  ["Kyrgyzstan", "KGZ", "AS"],
  ["Lao People's Democratic Republic", "LAO", "AS"],
  ["Latvia", "LVA", "EU"],
  ["Lebanon", "LBN", "AS"],
  ["Lesotho", "LSO", "AF"],
  ["Liberia", "LBR", "AF"],
  ["Libya", "LBY", "AF"],
  ["Liechtenstein", "LIE", "EU"],
  ["Lithuania", "LTU", "EU"],
  ["Luxembourg", "LUX", "EU"],
  ["Macao (China)", "MAC", "AS"],
  ["Macedonia, the former Yugoslav Republic of", "MKD", "EU"],
  ["Madagascar", "MDG", "AF"],
  ["Malawi", "MWI", "AF"],
  ["Malaysia", "MYS", "AS"],
  ["Maldives", "MDV", "AS"],
  ["Mali", "MLI", "AF"],
  ["Malta", "MLT", "EU"],
  ["Marshall Islands", "MHL", "OC"],
  ["Martinique", "MTQ", "NA"],
  ["Mauritania", "MRT", "AF"],
  ["Mauritius", "MUS", "AF"],
  ["Mayotte", "MYT", "AF"],
  ["Mexico", "MEX", "NA"],
  ["Micronesia, Federated States of", "FSM", "OC"],
  ["Moldova, Republic of", "MDA", "EU"],
  ["Monaco", "MCO", "EU"],
  ["Mongolia", "MNG", "AS"],
  ["Montenegro", "MNE", "EU"],
  ["Montserrat", "MSR", "NA"],
  ["Morocco", "MAR", "AF"],
  ["Mozambique", "MOZ", "AF"],
  ["Myanmar", "MMR", "AS"],
  ["Namibia", "NAM", "AF"],
  ["Nauru", "NRU", "OC"],
  ["Nepal", "NPL", "AS"],
  ["Netherlands", "NLD", "EU"],
  ["New Caledonia", "NCL", "OC"],
  ["New Zealand", "NZL", "OC"],
  ["Nicaragua", "NIC", "NA"],
  ["Niger", "NER", "AF"],
  ["Nigeria", "NGA", "AF"],
  ["Niue", "NIU", "OC"],
  ["Norfolk Island", "NFK", "OC"],
  ["Northern Mariana Islands", "MNP", "OC"],
  ["Norway", "NOR", "EU"],
  ["Oman", "OMN", "AS"],
  ["Pakistan", "PAK", "AS"],
  ["Palau", "PLW", "OC"],
  ["Palestine, State of", "PSE", "AS"],
  ["Panama", "PAN", "NA"],
  ["Papua New Guinea", "PNG", "OC"],
  ["Paraguay", "PRY", "SA"],
  ["Peru", "PER", "SA"],
  ["Philippines", "PHL", "AS"],
  ["Pitcairn", "PCN", "OC"],
  ["Poland", "POL", "EU"],
  ["Portugal", "PRT", "EU"],
  ["Puerto Rico", "PRI", "NA"],
  ["Qatar", "QAT", "AS"],
  ["Réunion", "REU", "AF"],
  ["Romania", "ROU", "EU"],
  ["Russian Federation", "RUS", "EU"],
  ["Rwanda", "RWA", "AF"],
  ["Saint Barthélemy", "BLM", "NA"],
  ["Saint Helena, Ascension and Tristan da Cunha", "SHN", "AF"],
  ["Saint Kitts and Nevis", "KNA", "NA"],
  ["Saint Lucia", "LCA", "NA"],
  ["Saint Martin (French part)", "MAF", "NA"],
  ["Saint Pierre and Miquelon", "SPM", "NA"],
  ["Saint Vincent and the Grenadines", "VCT", "NA"],
  ["Samoa", "WSM", "OC"],
  ["San Marino", "SMR", "EU"],
  ["Sao Tome and Principe", "STP", "AF"],
  ["Saudi Arabia", "SAU", "AS"],
  ["Senegal", "SEN", "AF"],
  ["Serbia", "SRB", "EU"],
  ["Seychelles", "SYC", "AF"],
  ["Sierra Leone", "SLE", "AF"],
  ["Singapore", "SGP", "AS"],
  ["Sint Maarten (Dutch part)", "SXM", "EU"],
  ["Slovakia", "SVK", "EU"],
  ["Slovenia", "SVN", "EU"],
  ["Solomon Islands", "SLB", "OC"],
  ["Somalia", "SOM", "AF"],
  ["South Africa", "ZAF", "AF"],
  ["South Georgia and the South Sandwich Islands", "SGS", "AN"],
  ["South Sudan", "SSD", "AF"],
  ["Spain", "ESP", "EU"],
  ["Sri Lanka", "LKA", "AS"],
  ["Sudan", "SDN", "AF"],
  ["Suriname", "SUR", "SA"],
  ["Svalbard and Jan Mayen", "SJM", "EU"],
  ["Swaziland", "SWZ", "AF"],
  ["Sweden", "SWE", "EU"],
  ["Switzerland", "CHE", "EU"],
  ["Syrian Arab Republic", "SYR", "AS"],
  ["Taiwan, Province of China", "TWN", "AS"],
  ["Tajikistan", "TJK", "AS"],
  ["Tanzania, United Republic of", "TZA", "AF"],
  ["Thailand", "THA", "AS"],
  ["Timor-Leste", "TLS", "AS"],
  ["Togo", "TGO", "AF"],
  ["Tokelau", "TKL", "OC"],
  ["Tonga", "TON", "OC"],
  ["Trinidad and Tobago", "TTO", "NA"],
  ["Tunisia", "TUN", "AF"],
  ["Turkey", "TUR", "EU"],
  ["Turkmenistan", "TKM", "AS"],
  ["Turks and Caicos Islands", "TCA", "NA"],
  ["Tuvalu", "TUV", "OC"],
  ["Uganda", "UGA", "AF"],
  ["Ukraine", "UKR", "EU"],
  ["United Arab Emirates", "ARE", "AS"],
  ["United Kingdom", "GBR", "EU"],
  ["United States", "USA", "NA"],
  ["United States Minor Outlying Islands", "UMI", "OC"],
  ["Uruguay", "URY", "SA"],
  ["Uzbekistan", "UZB", "AS"],
  ["Vanuatu", "VUT", "OC"],
  ["Venezuela, Bolivarian Republic of", "VEN", "SA"],
  ["Viet Nam", "VNM", "AS"],
  ["Virgin Islands, British", "VGB", "NA"],
  ["Virgin Islands, U.S.", "VIR", "NA"],
  ["Wallis and Futuna", "WLF", "OC"],
  ["Western Sahara", "ESH", "AF"],
  ["Yemen", "YEM", "AS"],
  ["Zambia", "ZMB", "AF"],
  ["Zimbabwe", "ZWE", "AF"],
];

const usStates = [
  {
    name: "Alabama",
    abbr: "AL",
  },
  {
    name: "Alaska",
    abbr: "AK",
  },
  {
    name: "American Samoa",
    abbr: "AS",
  },
  {
    name: "Arizona",
    abbr: "AZ",
  },
  {
    name: "Arkansas",
    abbr: "AR",
  },
  {
    name: "California",
    abbr: "CA",
  },
  {
    name: "Colorado",
    abbr: "CO",
  },
  {
    name: "Connecticut",
    abbr: "CT",
  },
  {
    name: "Delaware",
    abbr: "DE",
  },
  {
    name: "District Of Columbia",
    abbr: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbr: "FM",
  },
  {
    name: "Florida",
    abbr: "FL",
  },
  {
    name: "Georgia",
    abbr: "GA",
  },
  {
    name: "Guam",
    abbr: "GU",
  },
  {
    name: "Hawaii",
    abbr: "HI",
  },
  {
    name: "Idaho",
    abbr: "ID",
  },
  {
    name: "Illinois",
    abbr: "IL",
  },
  {
    name: "Indiana",
    abbr: "IN",
  },
  {
    name: "Iowa",
    abbr: "IA",
  },
  {
    name: "Kansas",
    abbr: "KS",
  },
  {
    name: "Kentucky",
    abbr: "KY",
  },
  {
    name: "Louisiana",
    abbr: "LA",
  },
  {
    name: "Maine",
    abbr: "ME",
  },
  {
    name: "Marshall Islands",
    abbr: "MH",
  },
  {
    name: "Maryland",
    abbr: "MD",
  },
  {
    name: "Massachusetts",
    abbr: "MA",
  },
  {
    name: "Michigan",
    abbr: "MI",
  },
  {
    name: "Minnesota",
    abbr: "MN",
  },
  {
    name: "Mississippi",
    abbr: "MS",
  },
  {
    name: "Missouri",
    abbr: "MO",
  },
  {
    name: "Montana",
    abbr: "MT",
  },
  {
    name: "Nebraska",
    abbr: "NE",
  },
  {
    name: "Nevada",
    abbr: "NV",
  },
  {
    name: "New Hampshire",
    abbr: "NH",
  },
  {
    name: "New Jersey",
    abbr: "NJ",
  },
  {
    name: "New Mexico",
    abbr: "NM",
  },
  {
    name: "New York",
    abbr: "NY",
  },
  {
    name: "North Carolina",
    abbr: "NC",
  },
  {
    name: "North Dakota",
    abbr: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbr: "MP",
  },
  {
    name: "Ohio",
    abbr: "OH",
  },
  {
    name: "Oklahoma",
    abbr: "OK",
  },
  {
    name: "Oregon",
    abbr: "OR",
  },
  {
    name: "Palau",
    abbr: "PW",
  },
  {
    name: "Pennsylvania",
    abbr: "PA",
  },
  {
    name: "Puerto Rico",
    abbr: "PR",
  },
  {
    name: "Rhode Island",
    abbr: "RI",
  },
  {
    name: "South Carolina",
    abbr: "SC",
  },
  {
    name: "South Dakota",
    abbr: "SD",
  },
  {
    name: "Tennessee",
    abbr: "TN",
  },
  {
    name: "Texas",
    abbr: "TX",
  },
  {
    name: "Utah",
    abbr: "UT",
  },
  {
    name: "Vermont",
    abbr: "VT",
  },
  {
    name: "Virgin Islands",
    abbr: "VI",
  },
  {
    name: "Virginia",
    abbr: "VA",
  },
  {
    name: "Washington",
    abbr: "WA",
  },
  {
    name: "West Virginia",
    abbr: "WV",
  },
  {
    name: "Wisconsin",
    abbr: "WI",
  },
  {
    name: "Wyoming",
    abbr: "WY",
  },
];

const EXCLUDE_DROPDOWN = ["locations.nonexistent_country"];

const ALL_SORTED_DROPDOWN = ALL.filter(
  (c) => !EXCLUDE_DROPDOWN.includes(c.name),
).sort((a, b) => a.name.localeCompare(b.name));

// let ISO_TO_NAME = ALL.reduce((o, v) => {
//   o[v.iso] = v.name;
//   return o;
// }, {});

// let allUsStateNames = usStates.mapBy("name");

export interface countryObject {
  id: Number;
  iso: String;
  name: String;
  region: String;
}

export {
  //   isoArrayFromIds,
  //   idArrayFromIso,
  //   isoFromId,
  //   idFromIso,
  locationData,
  usStates,
  //   allUsStateNames,
  //   ISO_TO_NAME,
};

export default {
  XX,
  EU,
  NA,
  SA,
  AF,
  OC,
  AS,
  AN,
  ALL,
  ALL_SORTED_DROPDOWN,
  //   ISO_TO_NAME,
};
